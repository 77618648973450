import 'app/extensions';

import ReactDOM from 'react-dom';
import LogRocket from 'logrocket';
import { App } from './app/App';

import './theme/color.scss';
import './styles.scss';
import './antd.less';

// TODO: Refactor ID to env
LogRocket.init('py1yy1/adm-demo');

ReactDOM.render(<App />, document.getElementById('root'));
